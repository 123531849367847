// extracted by mini-css-extract-plugin
export var heroWrapper = "MainHero-module--heroWrapper--2ytMf";
export var hero = "MainHero-module--hero--2eT3X";
export var clients = "MainHero-module--clients--1u0ft";
export var contact = "MainHero-module--contact--3rAuV";
export var awards = "MainHero-module--awards--1YQ7I";
export var clutch = "MainHero-module--clutch--2nnwX";
export var title = "MainHero-module--title--B845d";
export var contactButton = "MainHero-module--contactButton--2l0WP";
export var technologiesLayer = "MainHero-module--technologiesLayer--3GAGI";
export var techImgage = "MainHero-module--techImgage--1zLF5";
export var techName = "MainHero-module--techName--2flEr";
export var techBox = "MainHero-module--techBox--10P4W";