import React from "react";
import { Section } from "../Section";
import { MdxTestimonialCarousel } from "../Testimonials/TestimonialCarousel";
import { MdxTestimonialsData } from "../Testimonials/MdxTestimonials";

type Props = {
  testimonialsData: MdxTestimonialsData;
}

export const TestimonialsSection: React.FC<Props> = ({ testimonialsData }) => {

  return (
    <Section>
      <MdxTestimonialCarousel testimonialsData={testimonialsData}  />
    </Section>
  );
};
