import React from "react";
import { Link } from "../Link";
import tomorrowImg from "../../images/clients/tomorrow.svg";
import aspectoImg from "../../images/clients/aspecto.svg";
import kueezImg from "../../images/clients/kueez.svg";
import * as styles from "./TrustSection.module.scss";
import { ClutchReviews } from "../Awards/ClutchReviews";
import { Section } from "../Section";
import { AwardsList } from "../Awards";

export const TrustSection = () => {
  return (
    <>
      <div className={styles.clients_list_outer}>
        <Section className={styles.clients_list_inner}>
          <div className={styles.clients_list_title}>
            Companies that <span className={styles.underline}>trust</span> us:
          </div>
          <div className={styles.clients_list_group}>
            <Link to="https://www.tomorrow.io/">
              <img alt="tomorrow.io" src={tomorrowImg} />
            </Link>
            <Link to="https://weare.kueez.com/">
              <img alt="kueez" src={kueezImg} />
            </Link>
            <Link to="https://aspecto.io/">
              <img alt="aspecto.io" src={aspectoImg} />
            </Link>
          </div>
        </Section>
      </div>
      <Section className={styles.clients_info_outer}>
        <div className={styles.clients_info_wrapper}>
          <div className={styles.clients_info_stats}>
            <div className={styles.clients_info_results}>
              <div className={styles.results_wrapper}>
                <div className={styles.results_count}>16+</div>
                <div className={styles.results_type}>happy clients</div>
              </div>
              <div className={styles.results_wrapper}>
                <div className={styles.results_count}>70+</div>
                <div className={styles.results_type}>completed projects</div>
              </div>
            </div>
            <div className={styles.clients_info_results}>
              <div className={styles.results_wrapper}>
                <div className={styles.results_count}>20+</div>
                <div className={styles.results_type}>experienced engineers</div>
              </div>
              <div className={styles.results_wrapper}>
                <div className={styles.results_count}>
                  <span>8+</span>
                  <span className={styles.results_count_years}>years</span>
                </div>
                <div className={styles.results_type}>average experience</div>
              </div>
            </div>
          </div>
          <div className={styles.clients_info_overview}>
            <div className={styles.clients_info_overview_title}>
              Simplifying Technology for Your Business Success
            </div>
            <div className={styles.clients_info_overview_text}>
              At LeanyLabs, we believe in simplifying the intricate world of
              technology, allowing you to prioritize your core business
              functions. With years of expertise crafting bespoke software
              solutions, our mission is straightforward - deliver software
              solutions so businesses can focus on what they do best. As a
              reliable technological ally, we help clients build their
              innovations, strengthen their teams, automate their processes, and
              enhance their existing systems for maximum business benefits.
            </div>
            <ClutchReviews type="blurb" className={styles.clutch} />
          </div>
        </div>
      </Section>
      <div className={styles.clients_list_outer}>
        <Section className={styles.achievements}>
          <span className={styles.text}>
            <span className={styles.underline}>Achievements</span> &{" "}
            <span className={styles.underline}>Affiance</span>
          </span>
          <AwardsList />
        </Section>
      </div>
    </>
  );
};
