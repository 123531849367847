import React, { RefObject, useEffect, useState } from "react";

import * as styles from "./GridLayer.module.scss";
import { isBrowser } from "../../../utils/isBrowser";

interface GridLayerProps {
  containerRef: RefObject<HTMLDivElement>;
}

const GridLayer = ({ containerRef }: GridLayerProps) => {
  const [[gridWidth, gridHeight], setGridSize] = useState([0, 0]);

  useEffect(() => {
    if (!isBrowser()) return;

    const handleWindowResize = () => {
      if (!containerRef.current) {
        return;
      }

      setGridSize([window.innerWidth, containerRef.current.clientHeight]);
    };

    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const getGridCellSize = () => {
    if (isBrowser() && window.innerWidth >= 992) return 68;
    return 36;
  };

  const gridCellSize = getGridCellSize();

  return (
    <div className={styles.grid}>
      {[...Array(Math.ceil(gridHeight / gridCellSize))].map((_, rowIndex) => (
        <div key={rowIndex} className={styles.row}>
          {[...Array(Math.ceil(gridWidth / gridCellSize))].map(
            (_, columnIndex) => (
              <div
                key={columnIndex}
                className={styles.cell}
                style={{
                  width: gridCellSize,
                  height: gridCellSize,
                }}
              ></div>
            )
          )}
        </div>
      ))}
    </div>
  );
};

export { GridLayer };
