// extracted by mini-css-extract-plugin
export var clients_list_outer = "TrustSection-module--clients_list_outer--1Ni0P";
export var clients_list_inner = "TrustSection-module--clients_list_inner--2JxM0";
export var clients_list_title = "TrustSection-module--clients_list_title--3uoot";
export var clients_list_group = "TrustSection-module--clients_list_group--2HX6V";
export var clients_info_outer = "TrustSection-module--clients_info_outer--EwTgx";
export var clients_info_wrapper = "TrustSection-module--clients_info_wrapper--1EvSe";
export var clients_info_stats = "TrustSection-module--clients_info_stats--3pwNe";
export var clients_info_results = "TrustSection-module--clients_info_results--3eAxZ";
export var clients_info_overview_title = "TrustSection-module--clients_info_overview_title--AyYD8";
export var clients_info_overview_text = "TrustSection-module--clients_info_overview_text--U8i_k";
export var clients_info_overview = "TrustSection-module--clients_info_overview--1NwUv";
export var results_wrapper = "TrustSection-module--results_wrapper--tMWK0";
export var results_count = "TrustSection-module--results_count--3qH02";
export var results_type = "TrustSection-module--results_type--2YbSm";
export var results_count_years = "TrustSection-module--results_count_years--2c8Ug";
export var achievements = "TrustSection-module--achievements--1D-60";
export var text = "TrustSection-module--text--3Yz0D";
export var underline = "TrustSection-module--underline--3W5MJ";