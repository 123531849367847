import React from "react";
import { graphql } from "gatsby";
import { Section, SectionTitle } from "../Section";
import { Link } from "../Link";
import { getBlogPostUrl, getBlogUrl } from "../../utils/urls";
import { MdxImageSharp } from "../../types/images";
import { Buttons } from "../Buttons";
import cardTitleArrow from "../../images/card_title_arrow.svg";
import {
  Card,
  CardBody,
  CardGrid,
  CardImage,
  CardInner,
  CardTitle,
} from "../Card";
import { Image } from "../Image";
import * as styles from "./MdxBlogPosts.module.scss";
import { Button } from "../Button";

export type MdxBlogPostsData = {
  nodes: {
    excerpt: string;
    fields: {
      slug: string;
      readingTime: {
        text: string;
      };
    };
    frontmatter: {
      date: string;
      title: string;
      // author?: string;
      description?: string;
      featured: MdxImageSharp;
      tags: string[];
    };
  }[];
};

export const mdxBlogPostsQuery = graphql`
  fragment BlogPostsData on MdxConnection {
    nodes {
      excerpt
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featured {
          ...CardImageData
        }
      }
    }
  }
`;

type Props = {
  blogPostsData: MdxBlogPostsData;
};

export const MdxBlogPosts: React.FC<Props> = ({ blogPostsData }) => {
  const nodes = blogPostsData.nodes;

  return (
    <CardGrid className={styles.section_blog_cards}>
      {nodes.map((node, i) => {
        if (!node || !node.frontmatter || !node.fields || !node.fields.slug)
          return null;

        const title = node.frontmatter.title || node.fields.slug;
        const url = getBlogPostUrl(node.fields.slug);
        return (
          <Card key={node.fields?.slug} className={styles.card_wrapper}>
            <CardImage className={styles.card_image}>
              <Link to={url}>
                <Image image={node.frontmatter.featured} alt={title} />
              </Link>
            </CardImage>
            <div className={styles.card_content}>
              <div className={styles.card_header}>
                <div className={styles.card_tags}>
                  {node.frontmatter.tags.map((tag) => (
                    <div className={styles.card_tag}>#{tag}</div>
                  ))}
                </div>
                <div className={styles.card_readtime}>
                  {node.fields.readingTime?.text}
                </div>
              </div>
              <CardTitle className={styles.card_title}>
                <Link to={url} className={styles.card_title_link}>
                  {title}
                </Link>
              </CardTitle>
              <CardBody className={styles.card_body}>
                {node.frontmatter.description || node.excerpt}
              </CardBody>
              {i === 0 && (
                <Buttons className={styles.read_more_btn}>
                  <Button to={url} className={styles.card_btn}>
                    Read More
                  </Button>
                </Buttons>
              )}
            </div>
          </Card>
        );
      })}
    </CardGrid>
  );
};

export const MdxBlogPostsSection: React.FC<Props> = ({ blogPostsData }) => {
  if (!blogPostsData?.nodes?.length) return null;

  return (
    <Section className={styles.section_blog}>
      <div className={styles.section_blog_header}>
        <SectionTitle className={styles.section_blog_title}>
          <Link to={getBlogUrl()}>Our Blog</Link>
        </SectionTitle>
        <Buttons className={styles.section_blog_see_all}>
          <Link to={getBlogUrl()} className={styles.section_blog_see_all_link}>
            <span>See all articles</span>
            <img
              className={styles.section_blog_see_all_arrow}
              src={cardTitleArrow}
              alt="card_title_arrow"
            />
          </Link>
        </Buttons>
      </div>
      <MdxBlogPosts blogPostsData={blogPostsData} />
    </Section>
  );
};
