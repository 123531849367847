// extracted by mini-css-extract-plugin
export var section_blog = "MdxBlogPosts-module--section_blog--19eGk";
export var section_blog_header = "MdxBlogPosts-module--section_blog_header--LZ74H";
export var section_blog_title = "MdxBlogPosts-module--section_blog_title--2n2dX";
export var section_blog_see_all = "MdxBlogPosts-module--section_blog_see_all--2NMB6";
export var section_blog_see_all_link = "MdxBlogPosts-module--section_blog_see_all_link--2KQur";
export var section_blog_see_all_arrow = "MdxBlogPosts-module--section_blog_see_all_arrow--2o7PP";
export var section_blog_cards = "MdxBlogPosts-module--section_blog_cards--YyuUU";
export var card_wrapper = "MdxBlogPosts-module--card_wrapper--E1IqH";
export var card_title = "MdxBlogPosts-module--card_title--3r2-V";
export var card_body = "MdxBlogPosts-module--card_body--34XKs";
export var card_title_link = "MdxBlogPosts-module--card_title_link--3SYRJ";
export var card_header = "MdxBlogPosts-module--card_header--3WMWt";
export var card_content = "MdxBlogPosts-module--card_content--1DzRp";
export var card_image = "MdxBlogPosts-module--card_image--3bcbc";
export var card_tag = "MdxBlogPosts-module--card_tag--3P8ZC";
export var card_readtime = "MdxBlogPosts-module--card_readtime--errDB";
export var card_btn = "MdxBlogPosts-module--card_btn--3CcpD";
export var card_tags = "MdxBlogPosts-module--card_tags--39-5C";
export var read_more_btn = "MdxBlogPosts-module--read_more_btn--100BL";