import { graphql, useStaticQuery } from "gatsby";
import { Layout } from "../components/Layout";
import React from "react";
import { MdxBlogPostsSection } from "../components/Blog/MdxBlogPosts";
import { MdxCaseStudiesSection } from "../components/CaseStudies/MdxCaseStudies";
import { TestimonialsSection } from "../components/HomePage/TestimonialsSection";
import { MainHero } from "../components/HomePage/MainHero";
import { ServicesSection } from "../components/Services";
import { Seo } from "../components/Seo";

const Page: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      testimonials: allMdx(
        filter: {
          fields: { collection: { eq: "testimonials" } }
          frontmatter: { priority: { gt: 0 } }
        }
        sort: { fields: frontmatter___priority }
        limit: 5
      ) {
        ...TestimonialsData
      }
      cases: allMdx(
        filter: {
          fields: { collection: { eq: "cases" } }
          frontmatter: { tags: { eq: "home" } }
        }
        sort: { fields: frontmatter___priority }
      ) {
        ...CaseStudiesData
      }
      blog: allMdx(
        filter: { fields: { collection: { eq: "blog" } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 7
      ) {
        ...BlogPostsData
      }
    }
  `);

  return (
    <Layout>
      <Seo
        ogFullTitle
        title="Software Development Company"
        description="LeanyLabs provides custom software engineering services."
        maxImagePreview="large"
      />
      <MainHero />
      <TestimonialsSection testimonialsData={data.testimonials} />
      <ServicesSection />
      <MdxCaseStudiesSection caseStudiesData={data.cases} />
      <MdxBlogPostsSection blogPostsData={data.blog} />
    </Layout>
  );
};

export default Page;
