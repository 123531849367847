import React, { useEffect, useRef, useState } from "react";
import { PageHeroWrap } from "../PageHero";
import * as styles from "./MainHero.module.scss";
import { TrustSection } from "./TrustSection";
import { Technologies } from "../../utils/tech";
import { Button } from "../Button";
import { getContactUrl } from "../../utils/urls";
import { GridLayer } from "./GridLayer/GridLayer";

const technologies = Object.values(Technologies);

const TECH_NUMBER = 16;

export const MainHero = () => {
  const heroSectionRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div ref={heroSectionRef} className={styles.heroWrapper}>
        <GridLayer containerRef={heroSectionRef} />

        <PageHeroWrap className={styles.hero}>
          <h1 className={styles.title}>
            <strong>Unlock</strong> the Power of Tailored
            <br />
            <strong>Software Solutions!</strong>
          </h1>

          <Button to={getContactUrl()} className={styles.contactButton}>Contact Us</Button>

          <div className={styles.technologiesLayer}>
            {technologies.slice(0, TECH_NUMBER).map((tech) => (
              <div className={styles.techBox}>
                <img
                  key={tech.name}
                  title={tech.name}
                  alt={tech.name}
                  src={tech.image}
                  className={styles.techImgage}
                />
                <p className={styles.techName}>{tech.name}</p>
              </div>
            ))}
          </div>
        </PageHeroWrap>
      </div>
      <TrustSection />
    </>
  );
};
